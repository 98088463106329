<template>
  <div>
    <PageHeader />
    <b-overlay :show="isFetchingWithdrawSettings">
      <b-card class="mb-4">
        <ValidationObserver
          ref="botSettings"
          v-slot="{ handleSubmit }"
        >
          <form @submit.prevent="handleSubmit(onSubmit)">
            <b-form-group
              label="ยอดถอนต่ำสุด / ครั้ง (ต่อคน)"
              label-for="min_withdrawal"
            >
              <b-input-group>
                <b-form-input
                  id="min_withdrawal"
                  v-model="withdrawForm.min_withdrawal"
                ></b-form-input>
              </b-input-group>
            </b-form-group>
            <b-form-group
              label="ยอดถอนสูงสุด / ครั้ง (ต่อคน)"
              label-for="max_withdrawal"
            >
              <b-input-group>
                <b-form-input
                  id="max_withdrawal"
                  v-model="withdrawForm.max_withdrawal"
                ></b-form-input>
              </b-input-group>
            </b-form-group>
            <b-form-group
              label="จำนวนครั้งในการถอน / วัน (ต่อคน)"
              label-for="total_withdrawal"
            >
              <b-input-group>
                <b-form-input
                  id="total_withdrawal"
                  v-model="withdrawForm.total_withdrawal"
                ></b-form-input>
              </b-input-group>
            </b-form-group>
            <b-form-group
              label="ยอดถอนสะสม / วัน (ต่อคน)"
              label-for="cumulative_amount"
            >
              <b-input-group>
                <b-form-input
                  id="cumulative_amount"
                  v-model="withdrawForm.cumulative_amount"
                ></b-form-input>
              </b-input-group>
            </b-form-group>
            <b-form-group
              label="ถอนสูงสุดรวมกันทั้งระบบ / วัน"
              label-for="total_amount"
            >
              <b-input-group>
                <b-form-input
                  id="total_amount"
                  v-model="withdrawForm.total_amount"
                ></b-form-input>
              </b-input-group>
            </b-form-group>
            <b-form-group
              label="เปิด / ปิดระบบ Auto โอนเงินให้ผู้เล่นเมื่อกดอนุมัติ"
              label-for="total_amount"
            >
              <b-form-radio-group
                id="status"
                v-model="withdrawForm.status"
                name="status"
              >
                <b-form-radio value="enable">
                  {{$t('utils.status.enable')}}
                </b-form-radio>
                <b-form-radio value="disable">
                  {{$t('utils.status.disable')}}
                </b-form-radio>
              </b-form-radio-group>
            </b-form-group>
            <div class="text-right">
              <b-overlay
                :show="isUpdatingWithdrawSettings"
                rounded
                spinner-small
                class="d-inline-block"
              >
                <b-button
                  type="submit"
                  variant="primary"
                  block
                >
                  {{ $t('buttons.edit')}}
                </b-button>
              </b-overlay>
            </div>
          </form>
        </ValidationObserver>
      </b-card>
    </b-overlay>
  </div>
</template>

<script>
import { mapActions, mapGetters, mapState } from 'vuex'

export default {
  page: {
    title: 'Withdrawal Settings',
  },
  data() {
    return {
      withdrawForm: {
        min_withdrawal: 0,
        max_withdrawal: 0,
        total_withdrawal: 0,
        cumulative_amount: 0,
        total_amount: 0,
        status: 'enable',
      },
    }
  },
  computed: {
    ...mapState({
      isFetchingWithdrawSettings: (state) =>
        state.user.isFetchingWithdrawSettings,
      isUpdatingWithdrawSettings: (state) =>
        state.user.isUpdatingWithdrawSettings,
    }),
    ...mapGetters(['withdrawSettings']),
  },
  watch: {
    withdrawSettings(val) {
      if (val) {
        this.setData()
      }
    },
  },
  created() {
    // this.fetchWithdrawSettings()
  },
  methods: {
    ...mapActions(['fetchWithdrawSettings', 'updateWithdrawSettings']),
    setData() {
      if (this.withdrawSettings) {
        const data = JSON.parse(JSON.stringify(this.withdrawSettings))
        this.withdrawForm = { ...this.withdrawForm, ...data }
      } else {
        this.withdrawForm = {
          min_withdrawal: 0,
          max_withdrawal: 0,
          total_withdrawal: 0,
          total_amount: 0,
          cumulative_amount: 0,
          status: 'enable',
        }
      }
    },
    onSubmit() {
      //   this.updateWithdrawSettings(this.withdrawForm)
    },
  },
}
</script>
